<template>
  <div class="Modal">
    <div :class="`ModalHeader ModalHeader--${type}`">
      <h1>{{title}}</h1>
      <i
        class="Icon ModalCloseIcon"
        @click="$emit('close')"
      >close</i>
    </div>
    <div class="ModalBody">
      <slot />
      <div class="Block" v-if="challenge">
        <p>Type in {{challengeHint}} <strong>{{challenge}}</strong> to confirm:</p>
        <group>
          <input
            type="text"
            class="Input Input--block Input--bg"
            v-model="challengeEntered"
            ref="challenge"
          >
        </group>
      </div>
    </div>
    <div class="ModalFooter">
      <button-bar
        :is-busy="isConfirming"
        :is-disabled-confirm="challenge && challengeEntered !== challenge"
        :label-confirm="labelConfirm"
        :type-confirm="type"
        @cancel="$emit('close')"
        @confirm="confirm()"
      >
      </button-bar>
    </div>
  </div>
</template>

<script>

export default {
  emits: [
    'close',
  ],
  data() {
    return {
      isError: false,
      isConfirming: false,
      challengeEntered: '',
    }
  },
  props: {
    type: {
      type: String,
      default: () => 'warning',
    },
    title: {
      type: String,
      default: () => 'Confirmation required',
    },
    labelConfirm: {
      type: String,
      default: () => 'Confirm',
    },
    onConfirm: {
      type: Function,
    },
    challenge: {
      type: String,
    },
    challengeHint: {
      type: String,
    },
  },
  mounted() {

    //No challenge
    if (!this.challenge) {
      return
    }

    //Prevent pasting
    this.$refs.challenge.onpaste = (event) => {
      event.preventDefault()
      return false
    }

    //Focus
    this.$refs.challenge.focus()
  },
  methods: {

    /**
     * Confirm
     */
    confirm() {

      //Toggle flags
      this.isConfirming = true
      this.isError = false

      //Call handler
      this
        .onConfirm()
        .then(() => this.$emit('close'))
        .catch(() => this.isError = true)
        .finally(() => this.isConfirming = false)
    },
  },
}
</script>
